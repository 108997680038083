<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Scientific Notation</h2>
      <p class="mb-4">
        With the extremely small AND large numbers that we often encounter in the sciences, it is
        common to see numbers expressed in scientific notation. Scientific notation uses powers of
        10 to express very large and very small numbers in a simpler manner. For instance, consider
        Avogadro's number which is approximately
        <stemble-latex content="$602,200,000,000,000,000,000,000\text{.}$" />
        Instead of writing out all of those zeroes, we can write the number in scientific notation
        as
        <stemble-latex content="$6.022\times 10^{23}$" />
      </p>

      <p class="mb-4">
        When writing a number in scientific notation, you always start with one and only one
        non-zero digit in front of the decimal place. All other significant digits are then placed
        after the decimal point. You then multiply this number by the appropriate power of 10 such
        that this new expression equals the original number. Many consider the power of 10 to be the
        number of digits that the decimal place has to move. Having to move the decimal point to the
        right (i.e. for numbers less than 1) corresponds to powers of 10 that are negative while
        moving the decimal point to the left (i.e. for numbers greater than 1) corresponds to
        positive powers of 10. See the schematic below for a demonstration of this.
      </p>

      <p class="pl-6 mb-5">
        <v-img style="width: 250px" src="/img/assignments/sciNotation1.png" />
      </p>

      <p class="mb-6">
        <b>Helpful tip:</b> To enter a number in scientific notation on stemble (and most
        spreadsheet programs), enter 1.0E5 or 1.0e5, for 1.0 x 10<sup>5</sup> where the E# (or e#)
        denotes 10<sup>#</sup>
      </p>

      <p class="mb-4">Write each of the following numbers in decimal form:</p>

      <p class="pl-6 mb-2">a) <number-value :value="sciNot1" /></p>

      <calculation-input
        v-model="inputs.dec1"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">b) <number-value :value="sciNot2" /></p>

      <calculation-input
        v-model="inputs.dec2"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">c) <number-value :value="sciNot3" /></p>

      <calculation-input
        v-model="inputs.dec3"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-4">Write each of the following numbers in scientific notation:</p>

      <p class="pl-6 mb-2">d) <number-value :value="decNum4" /></p>

      <calculation-input
        v-model="inputs.sci4"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">e) <number-value :value="decNum5" /></p>

      <calculation-input
        v-model="inputs.sci5"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">f) <number-value :value="decNum6" /></p>

      <calculation-input
        v-model="inputs.sci6"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question349',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        dec1: null,
        dec2: null,
        dec3: null,
        sci4: null,
        sci5: null,
        sci6: null,
      },
    };
  },
  computed: {
    sciNot1() {
      return this.taskState.getValueBySymbol('sciNot1').content;
    },
    sciNot2() {
      return this.taskState.getValueBySymbol('sciNot2').content;
    },
    sciNot3() {
      return this.taskState.getValueBySymbol('sciNot3').content;
    },
    decNum4() {
      return this.taskState.getValueBySymbol('decNum4').content;
    },
    decNum5() {
      return this.taskState.getValueBySymbol('decNum5').content;
    },
    decNum6() {
      return this.taskState.getValueBySymbol('decNum6').content;
    },
  },
};
</script>
